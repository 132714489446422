/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    emailAnchor: {
        '@media screen and (max-width: 767px)': {
            marginBottom: '2.5rem',
        },
        '@media screen and (max-width: 1024px) and (min-width: 737px)': {
            '& [class*="bx-wrap"]': {
                width: '100% !important',
            },
        },
    },
}));

const EmailAnchor = () => {
    const classes = useStyles();

    return (
        <div className={classes.emailAnchor}>
            <div data-emailanchor="true" />
        </div>
    );
};

export default EmailAnchor;
